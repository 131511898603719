import React, { useEffect, useState,useRef } from 'react';
import Modal from '../../view/common/Modal'
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { getStatusList,getLeadRemarks  } from '../../store/action/allAction'; 
//import { useApolloClient } from '@apollo/client';
import { executeGraphQLQueryFinex, executeGraphQLMutationFinex } from "../../common/executeGraphQLQuery";
import { UPDATE_LEAD_STATUS,LEAD_DETAIL_QUERY } from '../../queries/sangam/leads.gql';
import { toast } from 'react-toastify';

const LostModal = ({MarkAsLost,hideModalMarkAsLost,rejection_type,statusId,leadDetail,masterdata,masterStatusData}) => {
    
    const radioRef = useRef(null);
   // const dispatch = useDispatch();
    const client="";
    const [inputField, setField] = useState({
        reason_id: '',
        comment: '',
        bank_id: '',
        rejection_type: (rejection_type =='ops_reject') ? 'ops_reject': 'lost'
    })
    useEffect(()=>{
        setField({
            ...inputField,
            rejection_type : (rejection_type =='ops_reject') ? 'ops_reject': 'lost'
        })
    },[rejection_type]) 
    const [errField, setErrField] = useState({
        reason_id: '',
        comment: '',
        bank_id: '',
        rejection_type:''
    })
       
    const handleChangeOption = (e,key) => { 
        setErrField({...errField,[key]:''})
        setField((prev) => ({
            ...prev,
            [key]: e.value
        }))
    }
    const radioHandle = (e,key) => { 
        const { id, value } = e.target;  
        setField((prev) => ({
            ...prev,
            [key]: value 
        }))
    }
    const validateForm = ()=>{
        let errOb = {}
        if(inputField.bank_id =='' && inputField.rejection_type && inputField.rejection_type =='bank_hard_reject'){
            errOb.bank_id = 'Please select bank list'
        }
        if(inputField.rejection_type =='' && rejection_type =='bank_reject'){
            errOb.rejection_type = 'Please select this field'
        }
        if(inputField.reason_id ==''){
            errOb.reason_id = 'Please select reason'
        }
        if(inputField.comment ==''){
            errOb.comment = 'Please enter comment'
        } 
        setErrField(errOb)
        return Object.keys(errOb).length ? true : false
    }
    const submitMarkAsLost = () => { 
        const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
        const dealer_id = loginUserInfo.dealer_id;
       if(!validateForm()) {  
            let passOb =  {
                update_type: "lost",
                status_id: (rejection_type =='lost')? [5]:[+statusId],
                lead_id: leadDetail.id,
                user_id: +dealer_id,
                reject_reason_id: inputField.reason_id,
                rejection_type : inputField.rejection_type !='' ? inputField.rejection_type:rejection_type ,
                // after_verified : 10,
                lost_form: {
                    lost_remark: inputField.comment
                }
              
            } 
            if(inputField.rejection_type && inputField.rejection_type =='bank_hard_reject'){
                passOb.reject_bank_id = inputField.bank_id;
                passOb.status_id = [5]
            }
            let mutation = UPDATE_LEAD_STATUS,
                variables = {
                    api_called_by: 'saathi_web',
                    UpdateLeadStatus:passOb,
                    partner_id : dealer_id
                }; 
                executeGraphQLMutationFinex(mutation, variables, client)
                .then((resp) => { 
                    let response = resp?.data?.update_lead_status || null; 
                    if (response) {
                        toast.success(response.message) 
                        getLeadDetail(leadDetail.id);
                    }
                    else {
                        getLeadDetail(leadDetail.id);
                        toast.error("Error");
                    }
                })
                .catch((err) => {
                    getLeadDetail(leadDetail.id);
                    toast.error("Error")
                })
            closeModal();
        }
    }  
    const getLeadDetail =(lead_id) => { 
        if(!lead_id) lead_id = 0;
		executeGraphQLQueryFinex(LEAD_DETAIL_QUERY(lead_id)).then((response) => {
			if (response && response.data) {
				let data = response.data.get_lead_detail[0];
				if (data) {
                    let payload = data
					//dispatch({ type: "GET_LEAD_DETAIL", payload }); 
                   // dispatch((getLeadRemarks({ lead_id: lead_id, source: 'WEB' })))
				}
                else{
                    let payload = {}
					//dispatch({ type: "GET_LEAD_DETAIL", payload }); 
                    toast.error("Lead not found")
                }   
			}
            else{
                if(response?.errors?.length)
                toast.error(response?.errors?.[0].message,{
                    toastId: 'timeout',
                });  
            }
            
        })
        .catch((error) => { 
            toast.error(error.toString().replace('ApolloError:',''));
        });
    }
    const closeModal = () => {
        if(inputField.rejection_type !='ops_reject' && radioRef?.current){ 
            radioRef.current.click()
        }
        let ob = { reason_id: '', comment: '', bank_id: '', rejection_type:''}
        setField(ob) 
        setErrField(ob)
        //document.body.classList.remove("overflow-hidden");
        hideModalMarkAsLost();
    };

    let status_id = (statusId)? +statusId : +leadDetail?.status_id;
    let tempList = masterStatusData?.filter(data => data?.statuslang?.status_id === status_id).map(v=>v.rejectreasons)?.[0] || [];
    let rejectionKey = (inputField.rejection_type && ['bank_hard_reject','bank_soft_reject'].includes(inputField.rejection_type) ) ?  'bank_soft_reject': inputField.rejection_type;  
    rejectionKey = (rejection_type =='ops_reject') ? rejection_type : rejectionKey; 
    let reasonOption = tempList?.filter(ob=>ob.status_id == status_id && ob.rejection_type ==rejectionKey).map((item)=>{
        return {
            value:item.id,
            label:item?.rejectreasonlang?.name
        }
    })  
     
    let bankList = (masterdata?.bank_list) ? masterdata?.bank_list :[] 
    const REJECTION_OPTION = [
        {label:'Yes',value:'bank_hard_reject'},
        {label:'No',value:'bank_soft_reject'}
    ] 
    
    

    return (
        <Modal isOpen={MarkAsLost} togglePopup={closeModal} width={500} >
            <div className="modal-header">
                <h1>{ (rejection_type =='ops_reject') ? 'Verification Failed': 'Close Lead'} </h1>
            </div>
            <div className="modal-body" >
                {
                    rejection_type !='ops_reject' ? 
                    <div className='loanfulfillment'>
                        <div className='loaninput'>
                            <input type="radio" required ref={radioRef}   name="lost_type" value="lost" onChange={(e)=>radioHandle(e,'rejection_type')} defaultChecked={(inputField.rejection_type=='lost')?true:false} />
                            <label htmlFor='loanproperty'>Mark as Lost</label>
                        </div>
                        {
                            [2,3].includes(leadDetail?.status_id) ? 
                            <div className='loaninput'>
                                <input type="radio" required name="lost_type" value="bank_reject"  onChange={(e)=>radioHandle(e,'rejection_type')} defaultChecked={(inputField.rejection_type=='bank_reject')?true:false}/>
                                <label htmlFor='homeloan'>Bank Reject</label>
                            </div>  : null
                        } 
                    </div>
                    : null 
                } 

                {
                   ['bank_reject','bank_hard_reject','bank_soft_reject'].includes(inputField.rejection_type) ? 
                    <fieldset className="single-select">
                        <div className="material">
                            <Select
                                options={REJECTION_OPTION}
                                placeholder="Do you want to Login With Other Bank"
                                className="react-select"
                                classNamePrefix="react-select"
                                onChange={(e)=>handleChangeOption(e,'rejection_type')}
                                value={REJECTION_OPTION.filter(ob=>inputField.rejection_type==ob.value)}
                            />
                            {   errField.rejection_type ?
                                <div className="error-msg-txt">{errField.rejection_type}</div> : null
                            }  
                        </div>
                    </fieldset> : null
                } 
                {
                    inputField.rejection_type =='bank_hard_reject' ? 
                    <fieldset className="single-select">
                        <div className="material">
                            <Select
                                options={bankList}
                                placeholder="Bank List"
                                className="react-select"
                                classNamePrefix="react-select"
                                onChange={(e)=>handleChangeOption(e,'bank_id')}
                                value={bankList.filter(ob=>inputField.bank_id==ob.value)}
                            />
                            {   errField.bank_id ?
                                <div className="error-msg-txt">Please select bank list</div> : null
                            }  
                        </div>
                    </fieldset> : null
                } 
                <fieldset className="single-select">
                    <div className="material"> 
                        <Select
                            options={reasonOption}
                            placeholder="List Of Reason"
                            className="react-select"
                            classNamePrefix="react-select"
                            onChange={(e)=>handleChangeOption(e,'reason_id')}
                            value={reasonOption.filter(ob=>inputField.reason_id==ob.value)}
                        />
                        {   errField.reason_id ?
                            <div className="error-msg-txt">{errField.reason_id}</div> : null
                        }
                    </div>
                </fieldset>
                <fieldset className="form-filed">
                    <div className="material"> 
                        <textarea
                            placeholder="Comment"
                            className="form-input" rows="3"
                            value={inputField.comment}
                            onChange={(e) => setField({ ...inputField, comment: e.target.value })}>
                        </textarea>
                        <label
                            data-label="Comment"
                            className="form-label"
                        >
                        </label>
                        {   errField.comment ?
                            <div className="error-msg-txt" style={{marginTop:-20}}>{errField.comment}</div> : null
                        } 
                    </div>
                </fieldset>

                <div className='newleadbtnflex' style={{marginTop:20}}>
                <button className="logintomovebtn" onClick={submitMarkAsLost}>Save</button>
                </div>
            </div>
        </Modal>
    )
}

export default LostModal;