import React, { useEffect, useState } from "react";
import { useLoanDetail } from "../../view/ruleEngine/component/loanDetailContext";
import {
  convertJsonToCsv,
  downloadFile,
  getNumberFormat_IN,
} from "../MyLeads/helpers/helpers";
import PageLoader from "../../view/elements/PageLoader";
import { toast } from "react-toastify";
import Modal from "../../view/common/Modal";
import NewLeadLoanOffer from "../my-tools/component/loanoffer/NewLeadLoanOffer";
import crypto from "../../config/crypto";
import { SAVE_LOAN_OFFER } from "../../queries/sangam/create.gql";
import { executeGraphQLMutationFinex } from "../../common/executeGraphQLQuery";

const BankOfferList = ({
  bankOffer,
  currentView,
  handleTabClick,
  loading,
  leadDetail,
  newLeadId = null,
}) => {
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  const { loanDetails, updateLoanDetails } = useLoanDetail();
  const [checkedOffers, setCheckedOffers] = useState([]);
  const [showView, setShowView] = useState(false);
  const [ShowAllFees, setShowAllFees] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [leadId, setLeadId] = useState(null);
  const [bankDetails, setBankDetails] = useState([]);
  const [allFees, setAllFees] = useState({});

  useEffect(() => {
    if (Object.keys(leadDetail).length || newLeadId) {
      setLeadId(leadDetail?.id || newLeadId);
    }
  }, [leadDetail, newLeadId]);

  const getDateAndTimeStr = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDay();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
  };

  const handleExportCSVFile = () => {
    const requiredOfferCol = JSON.parse(JSON.stringify(checkedOffers)).map(
      (offer) => {
        offer.bankId && delete offer.bankId;
        offer.bankLogo && delete offer.bankLogo;
        offer.propValue && delete offer.propValue;
        offer.documentSet && delete offer.documentSet;
        offer.messages = offer.messages?.join("||").replaceAll(",", "");
        offer.processingText = offer.processingText
          ?.replaceAll(",", "")
          .replaceAll("\n", "");
        offer.login_fee = offer.login_fee?.replaceAll(",", "");
        offer.ac_handling_charge = offer.ac_handling_charge?.replaceAll(
          ",",
          ""
        );
        offer.legal_technical_fee = offer.legal_technical_fee?.replaceAll(
          ",",
          ""
        );
        offer.e_stamp_charge = offer.e_stamp_charge?.replaceAll(",", "");
        offer.due_diligence_charge = offer.due_diligence_charge?.replaceAll(
          ",",
          ""
        );
        offer.CERSAI_charge = offer.CERSAI_charge?.replaceAll(",", "");
        offer.MODT_charge = offer.MODT_charge?.replaceAll(",", "");
        offer.NOI_charge = offer.NOI_charge?.replaceAll(",", "");
        offer.netMonthlyIncome = String(offer.netMonthlyIncome)?.replaceAll(
          ",",
          ""
        );
        offer.eligibalLoanAmount = String(offer.eligibalLoanAmount)?.replaceAll(
          ",",
          ""
        );
        offer.monthlyEMI = String(offer?.monthlyEMI).replaceAll(",", "");

        return { ...offer };
      }
    );
    const csvData = convertJsonToCsv(requiredOfferCol);
    downloadFile(csvData, "text/csv", `BRE_OFFERS_${getDateAndTimeStr()}.csv`);
  };

  const handleCheckboxChange = (item, isChecked) => {
    if (isChecked) {
      setCheckedOffers((prevCheckedOffers) => [...prevCheckedOffers, item]);
    } else {
      setCheckedOffers((prevCheckedOffers) =>
        prevCheckedOffers.filter((offer) => offer !== item)
      );
    }
  };

  const handleCopyOffer = async () => {
    let msgBody1 = "",
      msgBody2 = "";
    if (checkedOffers.length === 1) {
      msgBody1 = `Based on your profile and requirement, I have identified the best loan offer for you with ${checkedOffers[0].bankName} Bank. Please go through the details and note that this offer is subject to change basis the final lender policy.`;
    } else if (checkedOffers.length > 1) {
      msgBody1 = `These are the best suited loan offers for you based on your profile and requirements. Please go through the details & note that the offers are subject to change basis the final lender policy.`;
    }

    for (let i = 0; i < checkedOffers.length; i++) {
      const offer = checkedOffers[i];
      const msg = `
${offer.bankName} Bank
Loan amount: ${offer.eligibalLoanAmount}
Rate of Interest: ${offer.minRoi}
Tenure: ${offer.maxTenure}
EMI: ${offer.monthlyEMI}
Login Fee: ${offer.login_fee?.replace("Login Fee: ", "")}
Processing Fee: ${offer.processingText?.replace("Processing Fee: ", "")}`;
      msgBody2 += msg;
      msgBody2 += "\n\n";
    }

    const msgTemplate = `
Greetings Hi There,

${msgBody1}

${msgBody2}

Looking forward to your confirmation for taking the loan process ahead.

Regards,
${loginUserInfo.name}`;

    try {
      await navigator.clipboard.writeText(msgTemplate);
      toast.success("Copied!");
    } catch (error) {
      toast.error("Failed to copy offer");
    }
  };

  const togglePopup = () => {
    setShowView((prevShowView) => !prevShowView);

    if (!showView) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const toggleFessPopup = (index) => {
    const feesObj = {};
    const selectedOffer = bankOffer[index];
    feesObj["Processing Fees"] = selectedOffer?.processingText || "N/A";
    feesObj["Login Fee"] = selectedOffer?.login_fee || "N/A";
    feesObj["Account handling charges/Other Charges"] =
      selectedOffer?.ac_handling_charge || "N/A";
    feesObj["Legal and Technical Fee"] =
      selectedOffer?.legal_technical_fee || "N/A";
    feesObj["E-Stamp Charges"] = selectedOffer?.e_stamp_charge || "N/A";
    feesObj["Due Diligence Charges"] =
      selectedOffer?.due_diligence_charge || "N/A";
    feesObj["CERSAI Charges"] = selectedOffer?.CERSAI_charge || "N/A";
    feesObj["MODT Charges"] = selectedOffer?.MODT_charge || "N/A";
    feesObj["NOI Charges"] = selectedOffer?.NOI_charge || "N/A";
    setAllFees(feesObj);

    setShowAllFees((prevShowView) => !prevShowView);
    if (!ShowAllFees) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const toggleSuccessPopup = () => {
    setShowSuccess((prev) => !prev);

    if (!showSuccess) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const saveLoanOffer = async (bankData = [], lead_id) => {
    try {
      const data = bankData.map((obj) => {
        return {
          lead_id,
          roi: obj.minRoi,
          emi: obj.monthlyEMI,
          loan_amount: obj.eligibalLoanAmount,
          bank_id: obj.bankId,
          processing_fee: obj.processingText,
        };
      });
      const mutation = SAVE_LOAN_OFFER;
      const variables = {
        partner_id: loginUserInfo.user_data.dealer_id,
        api_called_by: "saathi_web",
        loanOfferInput: data,
      };

      const resp = await executeGraphQLMutationFinex(mutation, variables);
      if (!resp?.data?.save_loan_offer) throw new Error("Something went wrong");

      toggleSuccessPopup();
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };

  const handleApplyOffer = (bankDetails) => {
    setBankDetails([bankDetails]);
    if (!Object.keys(leadDetail).length && !newLeadId) {
      return togglePopup();
    } else {
      saveLoanOffer([bankDetails], leadId);
    }
  };

  const handleSavetoLead = () => {
    if (leadId) {
      return saveLoanOffer(checkedOffers, leadId);
    }
    return togglePopup();
  };

  const handleSkipAndContinue = (data) => {
    togglePopup();
  };

  const handleLeadCreated = (lead_id) => {
    const offerList = bankDetails.length > 0 ? bankDetails : checkedOffers;
    setLeadId(lead_id);
    togglePopup();
    saveLoanOffer(offerList, lead_id);
  };

  const handleGoToLead = () => {
    window.location.href = `/saathi-details-page/${crypto.encode(leadId)}`;
  };

  return (
    <>
      <div className="bankofferselect">
        <div style={{ display: "flex" }}>
          <div className="loantype">Your Offers</div>
        </div>

        {/* <div className="col-md-2">
                <label>Tenure</label>
                <Select
                  id="tenure"
                  options={""}
                  onChange=""
                  name="tenure"
                  value={""}
                  // getOptionLabel={}
                  // getOptionValue={}
                />
              </div> */}
      </div>

      <div className="leftbottomcontainer1">
        <div style={{ width: "60%" }}>
          {loanDetails.property_detail == "yes" && (
            <>
              <button
                className={currentView === "property" ? "active" : ""}
                onClick={() => {
                  handleTabClick("property");
                  setCheckedOffers([]);
                }}
              >
                Property Based Offers
              </button>
              <button
                className={currentView === "income" ? "active" : ""}
                onClick={() => {
                  handleTabClick("income");
                  setCheckedOffers([]);
                }}
              >
                Income Based Offers
              </button>
            </>
          )}
          {checkedOffers.length ? (
            <button onClick={handleExportCSVFile}>Download</button>
          ) : null}
        </div>

        <div>
          {loanDetails.property_detail == "yes" && (
            <div className="bankloantips">
              <span>
                <img
                  src="https://ambak.storage.googleapis.com/partner/3030/1724907775784.svg"
                  alt="tips"
                  width={24}
                  height={24}
                />
              </span>
              <div style={{ marginLeft: 10 }}>
                Income allows for a higher loan; customer can increase their
                property budget!
              </div>
            </div>
          )}
        </div>
      </div>
      {loading ? (
        <PageLoader loaderClass="loading" />
      ) : (
        bankOffer.map((item, index) => (
          <div className="bankoffercard">
            <div className="pcoffercard">
              <div className="offerpadding">
                <div className="bankofferbox">
                  <div className="bankcheckbox">
                    <input
                      type="checkbox"
                      name="bank_check"
                      id="bank_check"
                      checked={checkedOffers.includes(item)}
                      onChange={(e) =>
                        handleCheckboxChange(item, e.target.checked)
                      }
                    />
                  </div>
                  <div className="banklogo">
                    <img
                      src={item.bankLogo}
                      alt="thankyou"
                      width={50}
                      height={20}
                    />
                    <span>{item.bankName}</span>
                  </div>
                </div>
                <div className="priceofferbox">
                  <div>
                    <div className="offerprice">
                      ₹ {getNumberFormat_IN(item.eligibalLoanAmount)}
                      {/* {bankcard.eligibalLoanAmount}  */}
                    </div>
                    <div className="bankonwords">Max Amount</div>
                  </div>
                  <div>
                    <div className="offerprice">
                      ₹ {getNumberFormat_IN(item.monthlyEMI)}*
                      {/* {bankcard.monthlyEMI}* */}
                    </div>
                    <div className="bankonwords">Monthly EMI</div>
                  </div>
                  <div>
                    <div className="offerprice">
                      {item.minRoi}%*
                      {/* {bankcard.minRoi}%* */}
                    </div>
                    <div className="bankonwords">Rate of interest</div>
                  </div>
                </div>
                <div className="offergst">
                  <span></span>
                  {item.processingText}
                  {/* Processing Fees: 0.25% of loan amt + GST */}
                </div>
              </div>
              <div className="mobileonwordshide">*onwards</div>

              <div className="appylbtnborder">
                <button
                  className="applybtn"
                  onClick={() => handleApplyOffer(item)}
                >
                  Apply Now
                </button>
              </div>
            </div>
            <div className="offerfeeslist">
              <div className="offerpricelist">
                <div className="processingfees">
                  <div className="offergstpc">
                    <span></span>
                    {item.processingText}
                  </div>
                  <div
                    style={{
                      color: "blue",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                    onClick={() => toggleFessPopup(index)}
                  >
                    View All Fees
                  </div>
                </div>
                {/* <div className="offergstpc">
          <span></span>
          {item.login_fee}
        </div> */}
              </div>
              {/* <div className="offergstpc">
          Payout :  0.80-0.95%*
        </div> */}
            </div>
          </div>
        ))
      )}

      <div style={{ fontSize: 12 }}>
        *All offers are subject to document proofs submitted and lender policies
        and approval.
      </div>

      {checkedOffers.length > 0 && (
        <div className="loannewlead">
          <div className="bankselected">
            <input type="checkbox" disabled={true} checked={true} />
            <div>
              <span> {checkedOffers.length} </span> Offers shortlisted
            </div>
            <button className="selectedbankshare" onClick={handleSavetoLead}>
              Save to Lead
            </button>
            <button className="selectedbankshare" onClick={handleCopyOffer}>
              Copy Offers
            </button>
          </div>
        </div>
      )}

      <Modal isOpen={ShowAllFees} togglePopup={toggleFessPopup} width={700}>
          {Object.keys(allFees)?.map((feeKey, index) => (
            <div key={index}>
              <div className="offerlistingfees">
                <div className="feesheading" style={{ fontSize: 12,paddingBottom:10 }}>
                  <ul style={{ paddingLeft: "20px" }}>
                    <li style={{ listStyle: "initial" }}>
                      {feeKey} :{" "}
                      <span className="feessubheading">{allFees[feeKey]}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        {/* <div className="offerlistingfees">
          <div
            className="offerfees"
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            <span></span>
            <div>Processing Fees :</div>
            <div style={{ fontWeight: "600" }}>Hii</div>
          </div>
        </div> */}

       
      </Modal>

      <Modal isOpen={showView} togglePopup={togglePopup} width={900}>
        <NewLeadLoanOffer
          skipAndContinue={handleSkipAndContinue}
          leadCreated={handleLeadCreated}
        />
      </Modal>

      {showSuccess && (
        <Modal
          isOpen={showSuccess}
          togglePopup={toggleSuccessPopup}
          width={900}
        >
          <div className="newleadthankyou">
            <img
              src={require(`../../../src/saathi-web/images/thankyouimg.svg`)}
              alt="thankyou"
              width={300}
            />
            <h4>Offer Shortlisted!</h4>
            <p>
              Your preference for{" "}
              {checkedOffers.length > 0
                ? `${checkedOffers.length} Banks`
                : `${bankDetails[0]?.bankName} Bank`}{" "}
              has been saved.
            </p>
            <p>Go to the lead to continue to Login</p>
          </div>
          <div className="newleadbtnflex">
            {leadId && (
              <button className="submitbtnnewlead" onClick={handleGoToLead}>
                Go to Lead
              </button>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default BankOfferList;
