import React, { useEffect, useState, useMemo, useCallback } from "react";
import "../../../src/saathi-web/saathi-web-style/mainlead.css";
import FormFields from "../../../src/saathi-web/MyLeads/formFields/customerDetailsFormFields.json";
import { SAVE_CUST_DETAILS } from "../../queries/sangam/customer.gql";
import { executeGraphQLMutationFinex } from "../../common/executeGraphQLQuery";
import { toast } from "react-toastify";
import Formsy from "formsy-react";
import FormsyInputField from "../../view/elements/FormsyInputField";
import FormsySelect from "../../view/elements/FormsySelect";
import { useHistory, useParams } from "react-router-dom";
import crypto from "../../config/crypto";
import {
  AMOUNT_TYPE_OPTIONS,
  DISBURSE_REJECTION_ID,
} from "../../config/constant";

import AdditionalIncome from "../../view/elements/AdditionalIncome";
import Obligations from "./Obligations";

const DOB_MAX_DATE = new Date().setFullYear(new Date().getFullYear() - 18);
const DOB_MIN_DATE = new Date("1900-01-01 00:00:00");

const GENDER_OPTIONS = [
  { id: "1", label: "Male", value: "male" },
  { id: "2", label: "Female", value: "female" },
];

const FORM_FIELDS = FormFields["INCOME_DETAILS"];

const IncomeDetails = ({ leadDetail, masterdata, handleStepClick }) => {
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  const [incomeDetails, setIncomeDetails] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const [additionalIncomeCount, setAdditionalIncomeCount] = useState(0);
  const [employmentType, setEmploymentType] = useState(0);
  const [activeTab, setActiveTab] = useState("partner_name");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [additionalIncomeFormFields, setAdditionalIncomeFormFields] = useState(
    []
  );
  const [isFormTouch, setTouch] = useState({});

  let history = useHistory();
  const params = useParams();

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata } : {};
    options["gender"] = GENDER_OPTIONS;
    options["amount_type"] = AMOUNT_TYPE_OPTIONS;
    options["company_type"] = options["company_type"]?.map((ob) => ({
      ...ob,
      value: +ob.id,
    }));
    options["additional_income_type"] = options["additional_income_type"]?.map(
      (obj) => ({
        ...obj,
        value: obj.id,
      })
    );
    options["filtered_additional_income_type_0"] = options[
      "additional_income_type"
    ]?.map((ob) => ({
      ...ob,
      value: +ob.id,
    }));
    return options;
  }, [masterdata]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    if (leadDetail && leadDetail?.lead_details) {
      let record = { ...leadDetail?.lead_details };
      record["business_proof"] = leadDetail?.customer?.business_proof;
      record["additional_income"] = leadDetail?.customer?.additional_income;
      record["is_additional_income"] =
        leadDetail?.customer?.is_additional_income;
      record["is_obligation"] = leadDetail?.customer?.is_obligation;
      record["existing_emi_amount"] = leadDetail?.customer?.existing_emi_amount;
      record["emi_ending_six_month"] =
        leadDetail?.customer?.emi_ending_six_month;

      if (record.is_additional_income === "yes") {
        for (let i = 0; i < record.additional_income.length; i++) {
          handleAddAdditionalIncome(i);
          ALL_OPTIONS[`filtered_additional_income_type_${i + 1}`] = ALL_OPTIONS[
            `filtered_additional_income_type_${i}`
          ]?.filter(
            (obj) => obj.value !== record.additional_income[i].income_type_id
          );
        }
      }

      setEmploymentType(record.profession || 0);
      setIncomeDetails(record);
    }
  }, [leadDetail?.lead_details]);

  const handleInputChange = useCallback((event) => {
    const { id, value } = event.target;
    setTouch((prev) => ({ ...prev, [id]: true }));
    setIncomeDetails((prev) => ({ ...prev, [id]: value }));
  }, []);

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value && field_name) {
      setTouch({
        ...isFormTouch,
        [field_name]: true,
      });
      setIncomeDetails((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
    }
  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      if (field_name === "profession") {
        let filteredAddInc = [];
        setEmploymentType(data.value);
        if (data.value === 1) {
          filteredAddInc = ALL_OPTIONS["additional_income_type"];
        } else {
          filteredAddInc = ALL_OPTIONS["additional_income_type"].filter(
            (obj) => obj.value === 1 || obj.value === 5
          );
        }
        ALL_OPTIONS["filtered_additional_income_type"] = filteredAddInc;
      }
      setIncomeDetails((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
    }
  };

  const handleSubmit = (isExitPage = false) => {
    setIsSubmitDisabled(true);
    const {
      profession,
      salary_credit_mode,
      company_name,
      company_type,
      is_itr_filled,
      is_form_16_filled,
      gross_monthly_salary,
      monthly_salary,
      annual_income,
      is_additional_income,
      additional_income,
      business_proof,
      is_obligation,
      existing_emi_amount,
      emi_ending_six_month,
    } = incomeDetails;

    let lead_id = leadDetail?.id || "";
    const customer_id = leadDetail?.customer.customer_id;
    const customer = {
      is_additional_income,
      first_name: leadDetail?.customer.first_name,
      last_name: leadDetail?.customer.last_name,
      mobile: leadDetail?.customer.mobile,
      business_proof,
      is_obligation,
      ...(is_obligation === "yes"
        ? { existing_emi_amount, emi_ending_six_month }
        : {}),
    };

    if (additional_income?.length > 0) {
      const additionalIncome = additional_income?.map((income) => {
        return {
          ...(income.id ? { id: income?.id } : {}),
          lead_id,
          customer_id,
          customer_type: "customer",
          income_type_id: income.income_type_id || null,
          amount_type: income.amount_type,
          amount: income.amount,
          status: income.status,
        };
      });
      customer["additional_income"] = additionalIncome;
      customer["is_additional_income"] = "yes";
    }

    let reqBody = {
      profession,
      salary_credit_mode,
      ...(company_name ? { company_name } : {}),
      ...(company_type ? { company_type: `${company_type}` } : {}),
      is_itr_filled,
      is_form_16_filled,
      gross_monthly_salary,
      monthly_salary,
      annual_income,
    };

    let mutation = SAVE_CUST_DETAILS,
      variables = {
        api_called_by: "saathi_web",
        partner_id: loginUserInfo?.dealer_id,
        LeadAdditionalInput: {
          lead_id: lead_id,
          lead_details: reqBody,
          customer,
        },
      };

    executeGraphQLMutationFinex(mutation, variables)
      .then((resp) => {
        let response = resp?.data?.save_customer_basic_details || null;
        if (response && !response.success) {
          setIsSubmitDisabled(false);
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        setIsSubmitDisabled(false);
        if (isExitPage)
          return history.push(`/saathi-details-page/${params.lead_id}`);
        handleStepClick(4);
      })
      .catch((err) => {
        setIsSubmitDisabled(false);
        toast.error(err.message);
      });
  };

  let IncomeDetailsForm = useMemo(() => {
    return FORM_FIELDS.map((field) => {
      if (field.name === "dob") {
        field["maxDate"] = DOB_MAX_DATE;
        field["minDate"] = DOB_MIN_DATE;
      }
      return field;
    });
  }, []);

  IncomeDetailsForm = useMemo(() => {
    return FORM_FIELDS;
  }, [incomeDetails]);

  useEffect(() => {
    setFormFields(IncomeDetailsForm);
  }, []);

  const handleAddAdditionalIncome = (index) => {
    if (index < 5) {
      const isExisting = additionalIncomeFormFields.filter(
        (obj) => obj.id === `additional_income_type_${index}`
      );
      if (
        isExisting.length === 0 ||
        additionalIncomeFormFields[additionalIncomeFormFields.length - 1]
          .canAddMore
      ) {
        const dropDownObj = { id: `additional_income_type_${index}` };
        setAdditionalIncomeCount(index + 1);
        setAdditionalIncomeFormFields((current) => [...current, dropDownObj]);
      }
    }
  };

  const handleCheckBoxChange = (event) => {
    const { name, checked } = event.target;
    let value = "";
    if (name === "is_obligation") {
      value = checked ? "yes" : "no";
    } else {
      value = checked ? "1" : "0";
    }
    setIncomeDetails((current) => ({
      ...current,
      [name]: value,
    }));
  };

  const handleAdditionalIncomeType = (data, index) => {
    if (data) {
      ALL_OPTIONS[`filtered_additional_income_type_${index + 1}`] = ALL_OPTIONS[
        `filtered_additional_income_type_${index}`
      ].filter((obj) => obj.value !== data.value);
    }
  };

  const handleRemoveAdditionalIncome = (index) => {
    const existingAddIncomeField = [...additionalIncomeFormFields];
    const existingAddIncome = [...incomeDetails["additional_income"]];
    const removedEle = {
      ...existingAddIncome[index],
      status: "0",
      income_type_id: null,
      amount_type: "",
      amount: "",
    };
    existingAddIncomeField.splice(index, 1);
    if (existingAddIncomeField.length > 0) {
      existingAddIncomeField[
        existingAddIncomeField.length - 1
      ].canAddMore = true;
    }
    existingAddIncome.splice(index, 1);
    existingAddIncome.push(removedEle);
    setAdditionalIncomeCount(additionalIncomeCount - 1);
    setAdditionalIncomeFormFields(existingAddIncomeField);
    setIncomeDetails((currentValue) => ({
      ...currentValue,
      additional_income: existingAddIncome,
    }));
  };

  const handleSetAdditionalIncomeData = (data) => {
    setIncomeDetails((currentValue) => ({
      ...currentValue,
      additional_income: data,
    }));
  };

  const handleSetObligationData = (key, value, obj) => {
    if (!(key === null) || !(obj === null)) {
      setIncomeDetails((currentValue) => ({
        ...currentValue,
        ...(obj ? { ...obj } : { [key]: value }),
      }));
    }
  };

  const rejectDisable =
    leadDetail.reject_reason_id > 0 &&
    !DISBURSE_REJECTION_ID.includes(leadDetail.reject_reason_id) &&
    [2, 3].includes(leadDetail.status_id)
      ? true
      : false;

  const IS_LOST = leadDetail?.status_id == 5 ? true : false;

  const handleButtonClick = (data) => {
    let url = `/co-applicant-income/${crypto.encode(leadDetail?.id)}`;
    history.push(url);
  };

  return (
    <>
      <div>
        <h4>Income Details</h4>
        <br></br>

        <div className="tab-list">
          <div className="commontabs">
            <div>
              <div className="partnername">Primary</div>
              <div
                className={
                  activeTab === "partner_name"
                    ? "commontabsItem active"
                    : "commontabsItem"
                }
                onClick={() => handleTabClick("partner_name")}
              >
                <div className="newtabContent">
                  {" "}
                  {leadDetail?.customer?.first_name}
                </div>
              </div>
            </div>

            {leadDetail?.co_applicant?.length > 0 &&
              leadDetail?.co_applicant?.map((coApplicant, index) =>
                leadDetail?.co_applicant[index].ca_first_name &&
                leadDetail?.co_applicant[index].ca_type != "non_financing" ? (
                  <div>
                    <div className="coapplicantname">{`Co-Applicant - ${
                      index + 1
                    }`}</div>
                    <div className="commontabsItem">
                      <div
                        className="newtabContent"
                        onClick={() =>
                          handleButtonClick(`coApplicant_${index + 1}`)
                        }
                      >
                        {" "}
                        {leadDetail?.co_applicant[index].ca_first_name}{" "}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )
              )}
          </div>
        </div>

        <div className="row">
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={() => handleSubmit(false)}
          >
            <div className="row" style={{ marginBottom: 30 }}>
              {formFields.map((field, index) =>
                (employmentType === 0 && field.name === "annual_income") ||
                (employmentType === 0 && field.name === "company_type") ||
                (employmentType === 0 && field.name === "company_name") ||
                (employmentType === 0 && field.name === "salary_credit_mode") ||
                (employmentType !== 1 && field.name === "monthly_salary") ||
                (employmentType !== 1 &&
                  field.name === "gross_monthly_salary") ||
                (employmentType === 1 && field.name === "annual_income") ||
                (employmentType === 2 && field.name === "salary_credit_mode") ||
                (employmentType === 3 && field.name === "salary_credit_mode") ||
                (employmentType === 3 && field.name === "company_type") ||
                (employmentType === 3 &&
                  field.name === "company_name") ? null : [
                    "text",
                    "pattern-format",
                    "number-format",
                    "number",
                  ].includes(field.type) ? (
                  <fieldset className="form-filed col-md-6 " key={index}>
                    <FormsyInputField
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      value={(incomeDetails && incomeDetails[field.name]) || ""}
                      placeholder=" "
                      thousandsGroupStyle="lakh"
                      thousandSeparator={","}
                      decimalSeparator={"."}
                      label={field.label}
                      onChange={
                        ["pattern-format", "number-format"].includes(field.type)
                          ? (data) =>
                              handlePatternFormatChange(data, field.name)
                          : handleInputChange
                      }
                      format={field.ApplyNumberformat ? field.format : ""}
                      validations={
                        isFormTouch[field.name] ? field.validations : null
                      }
                      validationError={
                        incomeDetails[field.name] ||
                        incomeDetails[field.name] == ""
                          ? isFormTouch[field.name]
                            ? field.validationError
                            : ""
                          : ""
                      }
                      readOnly={field.readOnly}
                      required={field.required}
                    />
                  </fieldset>
                ) : field.type === "dropdown" ? (
                  <fieldset className="single-select col-md-6" key={index}>
                    <FormsySelect
                      name={field.name}
                      id={field.id}
                      inputProps={{
                        options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                        placeholder: field.label,
                        className: "react-select",
                        classNamePrefix: "react-select",
                        value:
                          ALL_OPTIONS &&
                          ALL_OPTIONS[field.optionsKey] &&
                          ALL_OPTIONS[field.optionsKey].filter(
                            ({ value }) => value == incomeDetails[field.name]
                          ),
                        isDisabled: field.readOnly,
                      }}
                      required={field.required}
                      value={incomeDetails && incomeDetails[field.name]}
                      onChange={(data) => handleSelectChange(data, field.name)}
                    />
                  </fieldset>
                ) : null
              )}
            </div>

            {/* ADDITIONAL INCOME */}
            {employmentType !== 0 && (
              <>
                {additionalIncomeFormFields.map((data, index) => (
                  <>
                    <div
                      className="additionalincome"
                      key={index}
                      style={{ marginTop: 20 }}
                    >
                      Additional Income - {index + 1}
                    </div>
                    <AdditionalIncome
                      index={index}
                      ALL_OPTIONS={ALL_OPTIONS}
                      setAdditionalIncomeType={handleAdditionalIncomeType}
                      removeAdditionalIncome={handleRemoveAdditionalIncome}
                      setAdditionalIncomeData={handleSetAdditionalIncomeData}
                      additionalIncomeData={incomeDetails["additional_income"]}
                      key={index}
                    />
                  </>
                ))}

                {additionalIncomeCount < 5 && (
                  <div
                    className="additionalincome"
                    onClick={() =>
                      handleAddAdditionalIncome(additionalIncomeCount)
                    }
                  >
                    <div className="coapplicantadd" style={{ marginTop: 20 }}>
                      <span>+</span> Additional Income
                    </div>
                  </div>
                )}
              </>
            )}

            {employmentType !== 0 && <hr></hr>}

            {/* OBLIGATION */}
            {employmentType !== 0 && (
              <>
                <div className="is-obligation">
                  <label>
                    {employmentType === 1
                      ? "Does the customer have any EMI based obligations"
                      : "Does the customer have any monthly obligations"}
                  </label>
                  <label className="toggle-label">No</label>
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      name="is_obligation"
                      id="is_obligation"
                      value="Yes"
                      checked={
                        incomeDetails["is_obligation"] === "yes" ? true : false
                      }
                      onChange={handleCheckBoxChange}
                    />
                    <span className="slider"></span>
                  </label>
                  <label className="toggle-label">Yes</label>
                </div>
              </>
            )}

            {incomeDetails["is_obligation"] === "yes" && (
              <div style={{ marginBottom: "15px" }}>
                <Obligations
                  setObligationDetails={handleSetObligationData}
                  customerType={"customer"}
                  leadDetail={leadDetail}
                />
              </div>
            )}

            {employmentType === 1 ? (
              <>
                <div className="property-identified">
                  <label>Have you filled Form 16B ?</label>
                  <label className="toggle-label">No</label>
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      name="is_form_16_filled"
                      id="is_form_16_filled"
                      value="Yes"
                      checked={
                        incomeDetails["is_form_16_filled"] === "1"
                          ? true
                          : false
                      }
                      onChange={handleCheckBoxChange}
                    />
                    <span className="slider"></span>
                  </label>
                  <label className="toggle-label">Yes</label>
                </div>
              </>
            ) : employmentType === 2 || employmentType === 3 ? (
              <>
                <div className="property-identified">
                  <label>Have you filled ITR ?</label>
                  <label className="toggle-label">No</label>
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      name="is_itr_filled"
                      id="is_itr_filled"
                      value="Yes"
                      checked={
                        incomeDetails["is_itr_filled"] === "1" ? true : false
                      }
                      onChange={handleCheckBoxChange}
                    />
                    <span className="slider"></span>
                  </label>
                  <label className="toggle-label">Yes</label>
                </div>
              </>
            ) : null}

            {employmentType === 3 ? (
              <>
                <div className="property-identified">
                  <label>Do you have business proof ?</label>
                  <label className="toggle-label">No</label>
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      name="business_proof"
                      id="business_proof"
                      value="1"
                      checked={
                        incomeDetails["business_proof"] == "1" ? true : false
                      }
                      onChange={handleCheckBoxChange}
                    />
                    <span className="slider"></span>
                  </label>
                  <label className="toggle-label">Yes</label>
                </div>
              </>
            ) : null}

            <div className="btn-save-remarks" style={{ marginTop: 40 }}>
              <div className="saveandexit">
                <button
                  type="submit"
                  className="markloatbrn"
                  onClick={() => handleSubmit(true)}
                  disabled={
                    !allowSubmit || rejectDisable || IS_LOST || isSubmitDisabled
                  }
                >
                  Save & Exit
                </button>
                <button
                  type="submit"
                  className="editnextbtn1"
                  disabled={
                    !allowSubmit || rejectDisable || IS_LOST || isSubmitDisabled
                  }
                >
                  Next
                </button>
              </div>
            </div>
          </Formsy>
        </div>
      </div>
    </>
  );
};

export default IncomeDetails;
