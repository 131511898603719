import React, { useState } from "react";
import InputField from "../../../../view/elements/Input";
import { LEAD_CREATION_FORM } from "../../../../queries/sangam/create.gql";
import { toast } from "react-toastify";
import {
  executeGraphQLMutationFinex,
  executeGraphQLQueryFinex,
} from "../../../../common/executeGraphQLQuery";
import { LEADS_QUERY } from "../../../../queries/sangam/cibil.gql";
import MobileAlready from "../../../mytool-cibil/MobileAlready";

const NewLeadLoanOffer = ({ skipAndContinue, leadCreated }) => {
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  const [newLeadDetails, setNewLeadDetails] = useState({});
  const [errors, setErrors] = useState({});
  const [alreadyExistLeads, setAlreadyExistLeads] = useState([]);
  const [showForm, setShowForm] = useState(true);

  const handleChange = (event) => {
    const { id, value } = event.target;
    setNewLeadDetails((current) => ({
      ...current,
      [id]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { checked, name } = event.target;
    const exLoanSubType = newLeadDetails["loan_sub_type"] || [];
    const value = name === "bt" ? 1 : 2;
    if (checked) {
      exLoanSubType.push(value);
    } else {
      exLoanSubType.splice(value - 1, 1);
    }
    exLoanSubType.sort((a, b) => a - b);
    setNewLeadDetails((current) => ({
      ...current,
      loan_sub_type: exLoanSubType,
    }));
  };

  const validate = () => {
    const errors = {};

    if (!newLeadDetails.first_name.trim()) {
      errors.first_name = "First Name is required";
    }
    if (!newLeadDetails.last_name.trim()) {
      errors.last_name = "Last Name is required";
    }
    if (!newLeadDetails.mobile.trim()) {
      errors.mobile = "Mobile is required";
    } else if (!/^[6-9]\d{9}$/.test(newLeadDetails.mobile)) {
      errors.mobile =
        "Mobile number must start with 6, 7, 8, or 9 and be exactly 10 digits";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const getAllLeadsByMobile = async (mobile) => {
    const filterData = { mobile };

    const response = await executeGraphQLQueryFinex(
      LEADS_QUERY(filterData, loginUserInfo?.dealer_id, loginUserInfo?.user_id)
    );

    setAlreadyExistLeads(response?.data?.leads[0]?.lead_list);
    setShowForm(false);
  };

  const handleSubmit = async (is_duplicate = false) => {
    try {
      if (!validate()) return;

      const mutation = LEAD_CREATION_FORM;
      const variables = {
        partner_id: loginUserInfo.user_data.dealer_id,
        api_called_by: "saathi_web",
        newLeadInput: {
          first_name: newLeadDetails?.first_name,
          last_name: newLeadDetails?.last_name,
          mobile: newLeadDetails?.mobile,
          loan_type: 1,
          ...(newLeadDetails?.loan_sub_type
            ? { loan_sub_type: newLeadDetails?.loan_sub_type?.join(",") }
            : {}),
          fulfillment_type: "self",
          assigned_partner_id:
            newLeadDetails.assigned_to || loginUserInfo?.dealer_id,
          parent_partner_id: loginUserInfo?.user_data?.parent_partner_id,
          ...(is_duplicate ? { is_duplicate } : {}),
        },
      };

      const headers = { is_demo: loginUserInfo.is_demo };

      const resp = await executeGraphQLMutationFinex(
        mutation,
        variables,
        headers
      );

      if (!resp?.data?.create_loan) {
        throw new Error("Something went wrong");
      }

      if (resp.data.create_loan.is_duplicate) {
        return getAllLeadsByMobile(newLeadDetails?.mobile);
      }

      toast.success("Success");
      leadCreated(resp.data.create_loan.id);
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };

  const handleBack = () => {
    setShowForm(true);
  };

  const handleSkipAndContinue = (data = null) => {
    skipAndContinue(false);
  };

  return (
    <>
      {showForm && (
        <>
          <div className="mainheadingall">Save Lead Details</div>
          <div className="row">
            <div className="col-sm-6">
              <InputField
                type="text"
                name="first_name"
                id="first_name"
                placeholder="First Name"
                label="First Name"
                onChange={handleChange}
                value={newLeadDetails["first_name"]}
                error={errors.first_name}
              />
            </div>
            <div className="col-sm-6">
              <InputField
                type="text"
                name="last_name"
                id="last_name"
                placeholder="Last Name"
                label="Last Name"
                onChange={handleChange}
                value={newLeadDetails["last_name"]}
                error={errors.last_name}
              />
            </div>
            <div className="col-sm-6">
              <div style={{ fontSize: 13, fontWeight: 500, marginBottom: 20 }}>
                Pick the home loan sub-type (if applicable)
              </div>
              <div className="loanfulfillment">
                <div className="loaninput">
                  <input
                    type="checkbox"
                    id="balancetransfer"
                    name="bt"
                    value="1"
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="balancetransfer">Balance Transfer</label>
                </div>
                <div className="loaninput">
                  <input
                    type="checkbox"
                    id="top-up"
                    name="top_up"
                    value="2"
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="top-up">Top-Up</label>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <InputField
                type="tel"
                name="mobile"
                id="mobile"
                maxLength={10}
                placeholder="Mobile Number"
                label="Mobile Number"
                onChange={handleChange}
                value={newLeadDetails["mobile"]}
                error={errors.mobile}
              />
            </div>
          </div>

          <div className="loannewlead">
            <button
              className="loannewleadbtn"
              onClick={() => handleSubmit(false)}
            >
              create Lead
            </button>
          </div>

          <div className="loannewlead">
            <div className="loanleadskip" onClick={handleSkipAndContinue}>
              Skip and Continue to Offers
            </div>
          </div>
        </>
      )}

      {!showForm && alreadyExistLeads.length > 0 && (
        <>
          <div>
            <img
              src={require("../../../../../src/saathi-web/images/left arrow.svg")}
              alt="not"
              style={{ cursor: "pointer" }}
              onClick={handleBack}
            />
          </div>
          <MobileAlready
            leadList={alreadyExistLeads}
            handleDataFromMobileAlready={handleSkipAndContinue}
            makeNewLead={handleSubmit}
            source="offer"
          />
        </>
      )}
    </>
  );
};

export default NewLeadLoanOffer;
