import React, { useState } from 'react';
import LoanOfferBasicDetails from './LoanOfferBasicDetails';
import LoanOfferIncomeDetails from './LoanOfferIncomeDetails';
import LoanOfferCoApplicant from './LoanOfferCoApplicant';
import LoanOfferProperty from './LoanOfferProperty';
import Modal from "../../../../view/common/Modal";
import NewLeadLoanOffer from './NewLeadLoanOffer';
import BankOfferList from '../../../CibilScore/BankOfferList';
import { useLoanDetail } from '../../../../view/ruleEngine/component/loanDetailContext';
import { toast } from 'react-toastify';
import { OfferService } from '../../../../service';
const incomeTypeMapping = {
    "1": "rent_amount",
    "2": "annual_bonus_amount",
    "3": "monthly_incentives_amount",
    "4": "monthly_reimbursements_amount",
    "5": "others",
    "6": "liquid_income"
  };

const coApplicantIncomeTypeMapping = {
    "1": "ca_rent_amount",
    "2": "ca_monthly_incentives_amount",
    "3": "ca_annual_bonus_amount",
    "4": "ca_monthly_reimbursements_amount",
    "5": "ca_others",
    "6": "ca_liquid_income"
};
const paymentModeMapping = {
    "1": "account",
    "2": "cash"
};
const PROPERTY_TYPE_MAPPING_OBJ = {
    9: "resale",
    11: "builder_endorsement",
    12: "builder_allotment",
    13: "authority_allotment",
    // 14: "allotment_transfer",
  };

const PROPERTY_SUB_TYPE_MAPPING_OBJ = {
    1: "ready_to_move",
    4: "contruction_linked",
    2: "plot",
    3: "plot_contruction",
};

const PROPERTY_REGISTRATION_TYPE_MAPPING_OBJ = {
    1: "khasra_khatoni_laldora",
    2: "regularised",
    3: "khasra_khatoni_laldora",
    4: "khasra_khatoni_laldora",
    5: "khasra_khatoni_laldora",
    6: "freehold",
    7: "leasehold",
};


const LoanAllInfo = ({allOptions, bankOfferVisibility, showBankOfferList, leadDetail}) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [showView, setShowView] = useState(false);
    const [showOffer, setShowOffer] = useState(false);
    const [bankOffer,setBankOffer]= useState([
        {
            "bankName": "Aditya",
            "bankId": 13,
            "bankLogo": "https://storage.googleapis.com/ambak/logo/bre-bank-logo/aditya_birla.png",
            "netMonthlyIncome": 107042,
            "maxTenure": 21,
            "propValue": 0,
            "eligibalLoanAmount": 13517935,
            "monthlyEMI": 119578,
            "minRoi": 9,
            "maxRoi": 9,
            "messages": [
                "Low bureau score: add someone with better credit history as the main applicant.",
                "Property shortlisted? Ask for the details to get a more accurate loan offer"
            ],
            "confidenceMeter": 85,
            "processingFee": 5000,
            "documentSet": {},
            "processingText": "Processing Fee: 0.5% of loan amt + GST (incld. Login Fee)",
            "login_fee": "Login Fee: Loan of - Upto Rs.10 Lacs: Rs.3,000 + GST; Above Rs.10 Lacs: Rs. 5,000 + GST",
            "ac_handling_charge": "",
            "legal_technical_fee": "",
            "e_stamp_charge": "",
            "due_diligence_charge": "",
            "CERSAI_charge": "",
            "MODT_charge": "",
            "NOI_charge": ""
        },
        {
            "bankName": "BOB",
            "bankId": 19,
            "bankLogo": "https://storage.googleapis.com/ambak/logo/bre-bank-logo/bob-logo.png",
            "netMonthlyIncome": 107042,
            "maxTenure": 21,
            "propValue": 0,
            "eligibalLoanAmount": 14071078,
            "monthlyEMI": 124930,
            "minRoi": 9.05,
            "maxRoi": 9.05,
            "messages": [
                "Low bureau score: add someone with better credit history as the main applicant.",
                "Property shortlisted? Ask for the details to get a more accurate loan offer"
            ],
            "confidenceMeter": 85,
            "processingFee": 5000,
            "documentSet": {},
            "processingText": "No Processing Fee",
            "login_fee": "Login Fee: Rs.10,000 + GST",
            "ac_handling_charge": "No Account Handling Charges",
            "legal_technical_fee": "",
            "e_stamp_charge": "Rs.2,000 - 2,500 + GST",
            "due_diligence_charge": "No Due Diligence Charges",
            "CERSAI_charge": "Rs.200 + GST",
            "MODT_charge": "",
            "NOI_charge": "Rs.15,000 + GST (Only Applicable in Maharashtra)"
        },
        {
            "bankName": "BOM",
            "bankId": 20,
            "bankLogo": "https://storage.googleapis.com/ambak/logo/bre-bank-logo/BOMLOGOFINAL.png",
            "netMonthlyIncome": 110042,
            "maxTenure": 21,
            "propValue": 0,
            "eligibalLoanAmount": 9608966,
            "monthlyEMI": 83750,
            "minRoi": 8.8,
            "maxRoi": 8.8,
            "messages": [
                "Low bureau score: add someone with better credit history as the main applicant.",
                "Property shortlisted? Ask for the details to get a more accurate loan offer"
            ],
            "confidenceMeter": 85,
            "processingFee": 5000,
            "documentSet": {},
            "processingText": "Zero Processing Fee",
            "login_fee": "No Login Fee",
            "ac_handling_charge": "0.10% Or Rs. 11,800 + GST",
            "legal_technical_fee": "Rs.10,000 + GST",
            "e_stamp_charge": "Rs.2,000 - 2,500 + GST",
            "due_diligence_charge": "Rs.2,500 + GST",
            "CERSAI_charge": "Rs.500 + GST",
            "MODT_charge": "0.30% + GST (Only Applicable in Maharashtra)\n0.60% + GST (Only Applicable in Karnataka)\n0.50% or Rs.50,000 + GST (Only Applicable in Telangana)",
            "NOI_charge": "Rs.15,000 + GST (Only Applicable in Maharashtra)"
        }
    ]
)
    const [errorFields,setErrorFields] = useState({})
    const { loanDetails, updateLoanDetails } = useLoanDetail();

    const [currentView, setCurrentView] = useState(loanDetails.property_detail=='yes'?"property":"income");
    const [loading, setLoading] = useState(false);
    const [newLeadId, setNewLeadId] = useState(null);

    const handleTabClick = (view) => {
        // if(view=="property"){
        //   updateLoanDetails("property_detail","yes")
        // }else{
        //   updateLoanDetails("property_detail","no")
        // }
         setCurrentView(view);
         handleBankOffer(view)
        };

    const transformIncomeEntries = (entries,IncomeMapping) => {
        const result = {};
        
        if(entries?.length){
            entries.forEach((entry) => {
              const { income_type, mode, amount } = entry;
              const key = IncomeMapping[income_type]; 
              const payment_mode = paymentModeMapping[mode]; 
          
              if (key) { // Ensure the key is valid
                result[key] = {
                  amount: parseFloat(amount), 
                  payment_mode: payment_mode 
                };
              }
            });
        }
      
        return result;
      };
      const transformCoApplicantDetails = (coapplicantDetails) => {
        return coapplicantDetails.map((applicant) => {
            const result = {
                ca_profession: applicant.co_employment_type ,
                ca_existing_emi_amount: parseFloat(applicant.co_current_emi),
                ca_emi_ending_six_month: parseFloat(applicant.co_emi_end_in_6_month),
                ca_applicant_other_income: transformIncomeEntries(applicant.income_entries, coApplicantIncomeTypeMapping)
              }
              if(applicant.co_employment_type == 1){
                if(applicant?.co_monthly_income){
                    result.ca_monthly_salary=parseFloat(applicant.co_monthly_income)
                }
                if(applicant?.co_gross_monthly_income){
                    result.ca_gross_monthly_salary=parseFloat(applicant.co_gross_monthly_income)
                }
              }else if (applicant.co_employment_type == 3) {
                result.income_calculation_mode = applicant.co_itr_filing === 'itrincome' ? "itr" : "bank_income"
                if (applicant.co_itr_filing === 'itrincome') {
                    result.ITR = loanDetails.CO_ITR
                }else{
                    result.bankIncome=transformBankEntries(applicant.acc_details)
                }
            }
          return result;
        });
      };
      const transformLeadDetails = (view=false)=>{
        const detail={
            loan_amount: loanDetails.loan_amount,
            profession: loanDetails.employment_type,
            cibil_score: loanDetails.credit_score,
            existing_emi_amount: loanDetails.current_emi,
            max_tenure: "",
            emi_ending_six_month: loanDetails.emi_end_in_6_month,
            is_property_decided:view ?(view=="property"?"Yes":"No"):loanDetails.property_detail=='yes'?"Yes":"No"
        }
        if(loanDetails.employment_type==1){
            detail.monthly_salary=loanDetails.take_home_salary
            detail.gross_monthly_salary = loanDetails.gross_salary
        }
        if(loanDetails.employment_type==3){
            detail.income_calculation_mode=loanDetails.itr_filing==='itrincome'?"itr":"bank_income"
        }
        return detail
      }

      const transformBankEntries = (entries) => {
          const result = [];
          
          if(entries?.length){
            entries.forEach((entry) => {
                result.push({
                    ac_type: entry.account_type, 
                    avg_balance: parseFloat(entry.amount) 
                })
            });
        }
      
        return result;
    };
    const handleBankOffer = async(view) => {
        const data = {
            loan_type: "",
            lead_details: transformLeadDetails(view),
            customer: {
                mobile: "",
                pancard_no: "",
                dob: "",
                company_type: loanDetails.company_type,
                total_work_ex: loanDetails.work_experience, 
                age: loanDetails.age 
            },
            bankIncome: transformBankEntries(loanDetails.acc_details),
            is_additional_income: loanDetails.additional_income=="yes"?"Yes":"No",
            other_income: { ...transformIncomeEntries(loanDetails.income_entries,incomeTypeMapping) },
            is_co_additional_income: loanDetails.coapplicant_available=="yes"?"Yes":"No",
            co_applicant: transformCoApplicantDetails(loanDetails.coapplicant_details),
            property_details:{
                property_city: loanDetails.city,
                expected_market_value: loanDetails.exp_market_value,
                registration_value: loanDetails.registration_value,
                property_type: PROPERTY_TYPE_MAPPING_OBJ[loanDetails.property_type], 
                property_sub_type: PROPERTY_SUB_TYPE_MAPPING_OBJ[loanDetails.property_sub_type],
            },
            fulfillment_type: ""

        }
        if(loanDetails?.agreement_type && (loanDetails.property_type == 9 || loanDetails.property_type == 13)){
            data.property_details.agreement_type=PROPERTY_REGISTRATION_TYPE_MAPPING_OBJ[loanDetails.agreement_type]
        }
        if (loanDetails.employment_type == 3) {
            data.lead_details.income_calculation_mode = loanDetails.itr_filing === 'itrincome' ? "itr" : "bank_income"
            if (loanDetails.itr_filing === 'itrincome') {
                data.ITR = loanDetails.ITR
            }
        }

        try {
            setLoading(true)
            const response = await OfferService.getBreOffers(data)
            if(response?.data?.status==200){
                const sortedOffers = response.data.data.sort((a, b) => b.eligibalLoanAmount - a.eligibalLoanAmount);
                setBankOffer(sortedOffers)
            }else{
                toast.error(response.data?.message)
            }
            setCurrentView(data.lead_details.is_property_decided=='Yes'?"property":"income")
            setShowView(false);
            setShowOffer(false);
            bankOfferVisibility(true);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
            toast.error(error?.message)   
        }
    };

    const togglePopup = () => {
        const exVal = localStorage.getItem("showLeadCreatePopup");
        localStorage.setItem("showLeadCreatePopup", !exVal);

        setShowView(prevShowView => !prevShowView);

        if (!showView) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
    };
    
    const handleSkipAndContinue = (data) => {
        togglePopup();
        handleBankOffer(false);
    }

    const handleStepClick = (step) => {
        setCurrentStep(step);
    };

    const setFieldError=(fieldName,errorMessage)=>{
        setErrorFields((prevErrors) => ({ ...prevErrors, [fieldName]: errorMessage }));
    }
    const handleErrorReset = (name)=>{
        setErrorFields((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[name];  
            return updatedErrors;
          });
    }

    const validateNext=(step)=>{
        if(step==1){
            if(loanDetails.employment_type==1){
                if(!loanDetails.work_experience){
                    setFieldError("work_experience","Work Experience is required")
                    return false
                }
            }else{
                
            }
        }else if(step ==2){
            if(loanDetails.employment_type==1){
                if(!loanDetails.take_home_salary){
                    setFieldError("take_home_salary","Take Home Salary is required")
                    return false
                }
            }
        }else if(step==3){
            if(loanDetails.coapplicant_details.length && loanDetails.coapplicant_available=='yes'){
                let check;
            for (let index = 0; index < loanDetails.coapplicant_details.length; index++) {
            const coapplicant = loanDetails.coapplicant_details[index];
            
            if (!coapplicant?.co_app_first_name) {
                console.log(`first_name is missing at coapplicant_details[${index}]`);
                setFieldError(`co_app_first_name${index}`,"First Name is required")
                check = `co_app_first_name${index}`;
            }
            
            if (!coapplicant?.co_employment_type) {
                console.log(`employment_type is missing at coapplicant_details[${index}]`);
                setFieldError(`co_employment_type${index}`,"Employment type is required")
                check = `co_employment_type${index}`;
            }

            }
            if(check){
                return false
            }
            }
        }
        return true
    }

    const handleNextStep = () => {
        if(!validateNext(currentStep)) return
        setCurrentStep(prevStep => {
            if (prevStep < 4) {
                return prevStep + 1;
            }
            return prevStep;
        });
    };

    const handleViewOffer = () => {
        if (Object.keys(leadDetail).length) {
            handleBankOffer(false);
        } else {
            const isPopupShow = localStorage.getItem("showLeadCreatePopup");
            if (isPopupShow === "true") return togglePopup();
            return handleBankOffer(false);
        }
    }

    const handleLeadCreated = (lead_id) => {
        setNewLeadId(lead_id);
        handleSkipAndContinue();
    }



    return (
        <>
            {showBankOfferList ? (
                <BankOfferList bankOffer={bankOffer} currentView={currentView} handleTabClick={handleTabClick} loading={loading} leadDetail={leadDetail} newLeadId={newLeadId} />
            ) : (
                <div className="editleadnew">
                    <div className="lefteditleadcont">
                        <section className="step-wizard">
                            <ul className="step-wizard-list">
                                {[1, 2, 3, 4].map((stepNumber) => (
                                    <li
                                        key={stepNumber}
                                        className={`step-wizard-item ${currentStep === stepNumber ? 'current-item' : ''}`}
                                        onClick={() => handleStepClick(stepNumber)}
                                    >
                                        <span className="progress-count">{stepNumber}</span>
                                    </li>
                                ))}
                            </ul>
                            <ul className="Editlead_flowlis">
                                <li onClick={() => handleStepClick(1)}>Basic Details</li>
                                <li onClick={() => handleStepClick(2)}>Income</li>
                                <li onClick={() => handleStepClick(3)}>Co-Applicant</li>
                                <li onClick={() => handleStepClick(4)}>Property</li>
                            </ul>
                        </section>
                    </div>
                    <div className="righteditleadcont">
                        {currentStep === 1 && <LoanOfferBasicDetails allOptions={allOptions} handleErrorReset={handleErrorReset} errorFields={errorFields}/>}
                        {currentStep === 2 && <LoanOfferIncomeDetails allOptions={allOptions} handleErrorReset={handleErrorReset} errorFields={errorFields}/>}
                        {currentStep === 3 && <LoanOfferCoApplicant allOptions={allOptions} handleErrorReset={handleErrorReset} errorFields={errorFields}/>}
                        {currentStep === 4 && <LoanOfferProperty allOptions={allOptions} handleErrorReset={handleErrorReset} errorFields={errorFields}/>}

                        <div style={{ textAlign: "end" }}>
                            {currentStep !== 4 &&
                                <button className="loanbtnnext" onClick={handleNextStep}>Next</button>
                            }
                            {currentStep === 4 && (
                                <button className="loanbtnnext" onClick={handleViewOffer}>View Offer</button>
                            )}
                        </div>
                    </div>

                    <Modal isOpen={showView} togglePopup={togglePopup} width={900}>
                        <NewLeadLoanOffer skipAndContinue={handleSkipAndContinue} leadCreated={handleLeadCreated} />
                    </Modal>
                </div>
            )}
        </>
    );
};

export default LoanAllInfo;
