import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink, Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { Link, useHistory } from "react-router-dom";

import secureStorage from "../../config/encrypt";
import ActionTypes from "../../store/action/action";
import MasterService from "../../service/MasterService";
import RadioBox from "../../../src/view/elements/Radiobox";
import Select from "react-select";
import Modal from "../../view/common/Modal";
import NewTeamMember from "./NewTeamMember";
import AddSubPartner from "./AddSubPartner";
import InputField from "../../view/elements/Input";
import Pagination from "../../view/pagination/Pagination";
import { RoleAccess } from "../../config/constant";
import "../../../src/saathi-web/saathi-web-style/mainlead.css";
import NoDataFound from "../../common/NoDataFound";

const MyTeamsList = (props) => {
  const history = useHistory();
  const [state, setState] = useState({
    newLead: false,
    addSubPartner: false,
    selectedOption: "teams_partner",
    list: [],
    partnerType: "",
    professions_list: [],
    search: false,
    filterData: { search_by: "", search_text: "" },
    paginationData: {},
    pageNo: 1,
    isValid: false,
  });
  const [is_allowed , set_is_allowed] = useState(false)
  const {
    newLead,
    addSubPartner,
    selectedOption,
    list,
    partnerType,
    professions_list,
    search,
    filterData,
    paginationData,
    pageNo,
    isValid,
  } = state;

  const searchByList = [
    { label: "Name", value: "organization" },
    { label: "Mobile", value: "dealership_contact" },
    { label: "Email", value: "dealership_email" },
  ];

  const status  = [
    
      { name : "Verified", id : "1",},
     {  name : "Pending" , id : "4"}
    
  ]
  const [partner_access , setPartnerAccess] = useState(false)
  useEffect(() => {
    getAccessList();
    getSubPartnerTypeFromUrl();
    updateReduxData();
    getProfessionlist();
   
  }, []);

  useEffect(() => {
    renderList();
  }, [selectedOption, search]);

  const getAccessList = async () => {
    const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));

   const response = await MasterService.get(`partner/partner/get-sub-partner-creation-access?partner_id=${loginUserInfo.dealer_id}&user_id=${loginUserInfo.user_id}`)
    if (response.data.data.sub_partner_creation_access === 1){
      setPartnerAccess(true)
    }
    else  {
      set_is_allowed(true)
      setState((prev) => ({
        ...prev,
        partnerType: "team_members",
        selectedOption: "team_members",
      }));
      
     }
  
    //  renderList();


  }
  useEffect(() => {
    validateInput(filterData.search_by, filterData.search_text , filterData.status);
  }, [filterData]);
  useEffect(() => {
     renderList()
  }, [is_allowed]);

  const getSubPartnerTypeFromUrl = () => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const type = searchParams.get("type");
      if (type === "sub_partner") {
        setState((prev) => ({
          ...prev,
          partnerType: "sub_partners",
          selectedOption: "sub_partner",
        }));
      } else if (type === "team_member") {
        setState((prev) => ({
          ...prev,
          partnerType: "team_members",
          selectedOption: "team_members",
        }));
      } else if (type === "verified") {
        setState((prev) => ({
          ...prev,
          partnerType: "verified",
          selectedOption: "sub_partner",
        }));
      }
    } catch (error) {
    }
  };

  const handleOptionChange = (sname, ovalue) => {
    let newFilterData = { ...filterData };
    if (sname === "status")
      {
        newFilterData[sname] = ovalue.id
      }
    if (sname === "search_by") {
      newFilterData[sname] = ovalue.value;
      newFilterData["search_text"] = "";
    } else if (sname === "search_text") {
      newFilterData[sname] = ovalue.target.value;
    }

    setState((prev) => ({ ...prev, filterData: newFilterData }));
    if (typeof props.optionChange === "function") {
      props.optionChange(sname, ovalue);
    }
  };
  const validateInput = (type, value , status) => {
    let valid = false;

    if (type === "dealership_contact") {
      valid = /^\d{10}$/.test(value);
    } else if (type === "organization") {
      valid = /^[A-Za-z\s]+$/.test(value);
    } else if (type === "dealership_email") {
      valid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    }
      else if (status)
        {
          valid = true
        }
 
    setState((prev) => ({ ...prev, isValid: valid }));
  };

  const handleSearch = () => {
    setState(
      (prev) => ({ ...prev, search: true }),
      () => renderList(1)
    );
  };

  const handleReset = () => {
    window.location.reload().

    setState((prev) => ({
      ...prev,
      filterData: { search_by: "", search_text: "" },
      search: false,
    }));
    renderList(1);
  };

  const getProfessionlist = async () => {
    const response = await MasterService.getMasters(
      ["lang", "professions_list"],
      {
        headers: { Authorization: props.auth_token },
      }
    );
    setState((prev) => ({
      ...prev,
      professions_list: response.data.data.professions_list,
    }));
  };

  const updateReduxData = async () => {
    const loginResponse = secureStorage.getItem("loginUserInfo");
    if (loginResponse) {
      props.updateStateIntoRedux(ActionTypes.LOGIN_USER, loginResponse);
      afterLoginSetState(loginResponse);
    } else {
      return <Redirect to={"/login"} />;
    }
  };

  const afterLoginSetState = (loginResponse) => {
    const role = loginResponse?.data?.user_data?.role || null;
    if (role !== "dealercentral") {
      return <Redirect to={"/"} />;
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    let partnerType = "";
    if (value === "sub_partner") {
      partnerType = "sub_partners";
      history.push("/my-saathi-teams?type=sub_partner");
    }
    if (value === "team_members") {
      partnerType = "team_members";
      history.push("/my-saathi-teams?type=team_member");
    }
    if (event.target.name === "verified") partnerType = "verified";
    setState((prev) => ({ ...prev, selectedOption: value, partnerType }));
  };

  const togglePopup = () => {
    setState((prev) => ({ ...prev, newLead: !newLead }));
    document.body.classList.toggle("overflow-hidden", newLead);
  };

  const SubPartnerPopup = () => {
    setState((prev) => ({ ...prev, addSubPartner: !addSubPartner }));
    document.body.classList.toggle("overflow-hidden", addSubPartner);
  };

  const renderList = async (pageNumber = pageNo) => {
    console.log ("++++++++++++++++++++++++++++" , pageNumber)
    try {
      let status = "";
      const searchParams = new URLSearchParams(window.location.search);
      const type = searchParams.get("type");
      const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
      const dealer_id = loginUserInfo.dealer_id;
      if (type === "sub_partner") {
        status = "sub_partners";
      }
      if (type === "team_member") {
        status = "team_members";
      }
     
      let reqData = {
        parent_partner_id: dealer_id,
        page_no: pageNumber,

        partner_type: status,
        ...filterData,
      };
      if (is_allowed === true ) {
        reqData.partner_type = "team_members";
      }
      if (type === "verified" && selectedOption === "sub_partner") {
        reqData.status = "1";
        reqData.partner_type = "verified";
      }

      const response = await MasterService.post(
        "dealer/dealer/dealer-list",
        reqData
      );
      setState((prev) => ({
        ...prev,
        list: response.data.data,
        paginationData: response.data.pagination.total,
        pageNo: pageNumber,
      }));
    } catch (error) {
    }
  };

  const onPaginate = (pageNumber) => {
    renderList(pageNumber);
  }
  const team_member = RoleAccess("Add_team");

  const add_sub_partner = RoleAccess("Add Sub_Partner");

  const goToKYCPage = (delaer_id_hash) => {
    let status = "";
    const searchParams = new URLSearchParams(window.location.search);
    const type = searchParams.get("type");
    if (type === "sub_partner") {
      history.push(`/my-saathi-teams-kyc-profile?${delaer_id_hash}&sub_partners`);
    }
    else {
      history.push(`/my-saathi-teams-kyc-profile?${delaer_id_hash}&team_members`);
    }
  };
  const handleOnEdit = () => {

  }

  return (
    <>
      <div className="LeadContainer">
        <div className="myleadcontainer">
          <div className="leftmyleadcontainer">
           {partner_access === true && <div className="myteamsflex">
              {[ "team_members" , "sub_partner" ].map((type) => (
                <div style={{ marginRight: 10 }} key={type}>
                  <RadioBox
                    type="radio"
                    name="lead_type"
                    id={type}
                    value={type}
                    label={type
                      .replace("_", " ")
                      .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())}
                    checked={selectedOption === type}
                    onChange={handleChange}
                  />
                </div>
              ))}
            </div>}
          </div>
          <div className="rightmyleadcontainer">
            <div className="newleadbutton">
              .
              {selectedOption === "team_members" && team_member === 1 ? (
                <button onClick={togglePopup}>+ Add Team</button>
              ) : (
                ""
              )}
              {selectedOption === "sub_partner" && add_sub_partner === 1 ? (
                <button onClick={SubPartnerPopup}>+ Add Sub Partner</button>
              ) : (
                ""
              )}
            </div>
            {/* <div className="notificationbutton">
              <img src={notifybell} alt="Notification" width={30} height={30} />
            </div> */}
          </div>
        </div>

        <div className="leadcontainer">
          <div className="searchinput">
            <div className="row">
              <div className="col-md-2">
                <label>Search Type</label>
                <Select
                  onChange={(ovalue) => handleOptionChange("search_by", ovalue)}
                  options={searchByList}
                  name="search_by"
                  placeholder={props.t("Search By")}
                  value={
                    searchByList.find(
                      ({ value }) => value === filterData.search_by
                    ) || null
                  }
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ value }) => value}
                />
              </div>

              <div className="col-md-2">
                <InputField
                  type="text"
                  name="search_text"
                  onChange={(e) => handleOptionChange("search_text", e)}
                  className="form-input-new"
                  title="SEARCH_TEXT"
                  placeholder="Enter Keyword"
                  label="Search Text"
                  value={filterData.search_text}
                >
                  
                  </InputField>
              </div>
              {selectedOption === "sub_partner" && 
               <div className="col-md-2">
               <label>Partner Status</label>
             <Select
                 onChange={(ovalue) => handleOptionChange("status", ovalue)}
                 options={status}
                 name="Status"
                 placeholder="Status"
                 // value={
                 //   searchByList.find(
                 //     ({ value }) => value === filterData.search_by
                 //   ) || null
                 // }
                 getOptionLabel={({ name }) => name}
                 getOptionValue={({ id }) => id}
               />
               </div>
}
             
              <div
                className="newleadbutton"
                style={{ marginTop: 20, textAlign: "right" }}
              >
                <button
                  onClick={handleSearch}
                  disabled={!isValid}
                  style={{
                    backgroundColor: isValid ? "#6E55B8" : "#cccccc",
                  }}
                >
                  Search
                </button>

                <button
                  onClick={handleReset}
                  // disabled={!isValid}
                  style={{
                    border: "1px solid #6E55B8",
                    backgroundColor: "white",
                    color: "#6E55B8",
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
       
        <div className="tablecontainer">
        <div style={{margin:"0px 20px"}}>
          <table>
            <thead style={{position:"sticky",top:"0"}}>
              <tr>
                {/* {[
                  "ID No.",
                  "Name",
                  "Designation",
                  "Status",
                  "Phone Number",
                  "Email id",
                  "Created Date",
                  "Last Login Date",
                  // "Action",
                ].map((header) => (
                  <th key={header}>{header}</th>
                ))} */}
                <th>ID No.</th>
                <th>Name</th>
                { selectedOption === "team_members" && <th>Designation</th>}
                { selectedOption === "sub_partner"   && <th>Verified-Status</th>}
                <th>Phone number</th>
                <th>Email ID</th>
                <th>Crated Date</th>
                { selectedOption != "team_members"   &&   <th>Payout Applicable</th>}
              
                <th>Last Login Date</th>
              </tr>
            </thead>
            <tbody>
              {list.length > 0 ? list.map((item) => (
                <tr
                  key={item?.id}
                  onClick={() => goToKYCPage(item.dealer_id_hash)}
                >
                  <td>{item?.id}</td>
                  <td>{item?.first_name}</td>
                 {  selectedOption === "team_members" && <td>{item?.designation || '--'}</td>}
                  { selectedOption === "sub_partner" ? (item?.status == 1) ? <td style={{ color: "blue" }}>Verified</td> :  <td style={{ color: "red" }}>Pending</td>  : ""}
                  <td>{item?.dealership_contact || '--'}</td>
                  <td>{item?.dealership_email || '--'}</td>
                  <td>{new Date(item.created_date).toLocaleDateString()}</td>
                  {selectedOption === "sub_partner" &&
                    <>
                    <td>{item?.is_payout_applicable === 0 ? "No" : "Yes"}</td>
                  <td>
                    {item.last_login_date != null
                      ? new Date(
                          item.last_login_date
                        ).toLocaleDateString()
                      : "--"} 
                      {"  "}
                      {item.last_login_date != null
                      ? new Date(
                          item.last_login_date
                        ).toLocaleTimeString()
                      : "--"}
                  </td>
                  </>}
                  <td>
                  {item.last_login_date != null
                      ? new Date(
                          item.last_login_date
                        ).toLocaleTimeString()
                      : "--"}
                  </td>
                  {/* <td>
                    <img src={actionicon} alt="Action" width={18} height={18} />
                  </td> */}
                </tr>
              )) : <td colspan='8' style={{textAlign : "center"}}><NoDataFound/></td>}
            </tbody>
          </table>
        </div>

        <Pagination
          pageCount={paginationData / 10}
          activePage={1}
          onPaginate={onPaginate}
        />
        </div>

       

        <Modal isOpen={newLead} togglePopup={togglePopup} width={600}>
          <NewTeamMember />
        </Modal>

        <Modal isOpen={addSubPartner} togglePopup={SubPartnerPopup} width={800}>
          <AddSubPartner professions_list={professions_list} />
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth_token: state.authToken,
  user_loggedin: state.userLoggedIn,
  dealer_id: state.dealer_id,
});

const mapDispatchToProps = (dispatch) => ({
  updateStateIntoRedux: (actionType, stateData) =>
    dispatch({ type: actionType, componentState: stateData }),
});

export default withTranslation("dealer")(
  connect(mapStateToProps, mapDispatchToProps)(MyTeamsList)
);
