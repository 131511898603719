import React, { useContext, useEffect, useRef, useState } from "react";
import InputField from "../../../view/elements/Input";
import { fileUploader, getUserInfo, postApiUtility } from "../../Utility/user.utility";
import { toast } from "react-toastify";
import ValidatorIcon from "./Icons/ValidatorIcon";
import { PartnerOnBordingContext } from "../../PartnerContextApi/PartnerOnBordingContext";
import FileInput from "./Input/FileInput";
import { isFileTypeAllowed } from "../../Utility/file.utility";
import MasterService from "../../../service/MasterService";


export const PanCardKyc = () => {
  const { 
    error,
    setError,
    kycData, 
    showNext,
    setKycData, 
    isLoading, 
    setIsLoading 
  } = useContext(PartnerOnBordingContext);

  const [isPanVerified, setPanVerified] = useState(false);
  const [partner_id, setPartnerId] = useState('');
  const [showIcons, setShowIcons] = useState(false);
  const [panKycData, setPanKycData] = useState({
    panNumber: '',
    pan_card: '',
    pan_card_file: ''
  });
  const [docs, setDocs] = useState({
    kyc_master_id: 3,
    file_name: '',
    file_url: '',
  });
  const [disabled , setDisabled] = useState(true)

  const panKycDataRef = useRef(panKycData);
  panKycDataRef.current = panKycData;

   useEffect(() => {
    const userInfo = getUserInfo();
    const { dealer_id } = userInfo;
    setPartnerId(dealer_id);
    if (panKycData?.panNumber.length === 10) {
      fetchAndVerifyPan();
    }
  }, [panKycData?.panNumber]);

  const handleOnChange = async (e) => {
    const {id='', value='', files=[]} = e?.target;
    const capitalizedValue = value.toUpperCase();
   if(id === 'pan_card' && value.length === 10) {
    setPanKycData((prevState) => ({
       ...prevState,
        panNumber: capitalizedValue,
        pan_card_file: null
      }));
    setKycData((prevState) => ({
      ...prevState,
        pan_number: capitalizedValue,
      }));
  } else if (id === 'pan_card_file') {
    if (files && !isFileTypeAllowed(files[0])) {
      return;
    }
    setPanKycData( (prevState) => {
      const updatedState = {
        ...prevState,
        pan_card_file: files[0]
      };
      panKycDataRef.current = updatedState;
      return updatedState;
     });
      setTimeout(() => {
        handleFileUpload(panKycDataRef.current.pan_card_file);
      }, 500);
  }
 }

 const handleFileUpload = async (file) => {
  setIsLoading(true);
  try {
    if (!kycData.pan_number.length === 10) {
      toast.error('Please enter pan number first.');
      setIsLoading(false);
    } else {
      const response = await fileUploader(file);
      setKycData((prevState) => ({
        ...prevState,
        pan_card_url: response?.file_url,
        pan_card_file_name: response?.file_name,
        docs : [...prevState.docs, {
          kyc_master_id: kycData?.typeOfHolder === 'Individual or Person' ? 3 : 6,
          file_name: response?.file_name,
          file_url: response?.file_url,
        }]
      }))
      setIsLoading(false);
      setError({...error, pan_card_file: false});
    }
  } catch (error) {
    console.error('Error uploading file:', error);
    setIsLoading(false);
    setError({...error, pan_card_file: true});
  }
};

 const fetchAndVerifyPan = async () => {
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
    const dealerId = loginUserInfo.user_data.dealer_id;
    const user_id = loginUserInfo.user_data.user_id;
  setIsLoading(true);
      let user_type= "1"
      let gst_required = false;
      let gst = ""

      const validatePanResponse = await postApiUtility('partner/partner/validate-PAN', {
        "pan_number": panKycData?.panNumber,
        "partner_id": partner_id
      })
      if(validatePanResponse?.data?.data?.message === 'New PAN') {
      const panData = await MasterService.get(`/partner/partner/pan-validation?pan_number=${panKycData?.panNumber}&user_id=${user_id}&partner_id=${partner_id}`);
      

      if( panData?.data?.data?.data?.code === 400) {
        toast.error('Invalid PAN Number')
        setKycData( (prevState) => ({
        ...prevState,
          pan_number: ''
        }))
        setError({...error, pan_number: true});
        setShowIcons(true);
        setIsLoading(false);
        setKycData((prevState) => ({
          ...prevState,
          is_pan_verified: 2,
        }));
        return;
      } else if(panData?.status === 200 && panData?.data?.data?.data?.code === 200) {
        setDisabled(false)
        const response = panData?.data?.data?.data
        
        if (panData?.data?.data?.applicant_type == "Individual" && panData?.data?.data?.gst_required === true) {
            user_type = "2"
            gst_required = true
        } else if (panData?.data?.data?.applicant_type == "Individual" && panData?.data?.data?.gst_required === false) {
          user_type = "1"
          gst_required = false
        } else if (panData?.data?.data?.applicant_type == "company" && panData?.data?.data?.gst_required === true) {  
          gst_required = true
          user_type = "2"
        } else if (panData?.data?.data?.applicant_type == "company" && panData?.data?.data?.gst_required === false) {  
          user_type = "2"
          gst_required = true
        }
        if (panData?.data?.data?.gstDetails?.data?.gstinList[0]?.gstin){
           gst = panData?.data?.data?.gstDetails?.data?.gstinList[0]?.gstin
        }
        setKycData( (prevState) => ({
          ...prevState,
          ...response,
          pan_number: panKycData?.panNumber,
          applicant_type: response?.typeOfHolder == "Company" ? "2" : "1",
          is_pan_verified: 1,
          gst_required:gst_required,
          gst_number : gst
        }))
        setError({...error, pan_number: false});
        setShowIcons(true);
        setIsLoading(false);
        toast.success('Pan Number Verification Success')
      } else {
        toast.error(`PAN Verification Failed : ${panData?.data.errors ?? ''}`)
        setKycData( (prevState) => ({
          ...prevState,
          pan_number: ''
        }))
        setShowIcons(true);
        setIsLoading(false);
        setKycData((prevState) => ({
          ...prevState,
          is_pan_verified: 2,
        }));
      }
    }
    else toast.error("Pan Already Exist")
   
 };

 const handleInvalidPanIcon = () => {
  setPanKycData(() => ({ panNumber: ''}))
  setShowIcons(false);
 };

 const maskPanNumber = (pan) => {
  if (pan.length !== 10) return pan;
  return pan[0] + '*****' + pan.slice(-4);
 };

  return (
    <div>
      <h6>PAN Card Details</h6>
      <div>
        <div col-md-12>
          <InputField
            type="text"
            name="pan_card"
            id="pan_card"
            placeholder="Enter PAN Number * "
            maxLength="10"
            is_label_required="true"
            onChange={handleOnChange}
            value={kycData.isPanKycCompleted ? maskPanNumber(kycData.pan_number) : kycData.pan_number || panKycData.panNumber}
            // disabled={kycData.isPanKycCompleted || isLoading}
            error={error?.pan_number}
            required={true}
            onFocus={true}
          >
            {!isLoading && 
              <>
              {/* {showIcons || kycData.isPanKycCompleted ? (
                <ValidatorIcon onPress={() => 
                  handleInvalidPanIcon()}
                  isValid={kycData.is_pan_verified === 1}/>
              ) : null} */}
              </>
            }
          </InputField>
        </div>
        <FileInput
          name="pan_card_file"
          id="pan_card_file"
          placeholder={kycData?.pan_card_file_name ?? "Pan Card"}
          onChange={handleOnChange}
          file={panKycDataRef.current.pan_card_file}
          fileUrl={kycData?.pan_card_url}
          error={error.pan_card_file}
          required={showNext === 3}
         
        />
      </div>
    </div>
  );
};
